:root {
  /* Samsung Galaxy Tab S2 */
  --layout-tablet-landscape: 2048px;
  --layout-tablet-portrait: 1536px;
}

body {
  padding-top: 32px;
  font-family: 'Public Sans', sans-serif;
  font-weight: normal;
  background-color: #f9f9f9;
}

/* 1280x800 */
@media (max-width: 800px) {
  .content-container {
    margin-left: 0;
    padding: 16px;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .content-container {
    margin-left: 100px;
    padding: 22px;
  }
}

@media (min-width: 1281px) {
  .content-container {
    margin-left: 256px;
    padding: 32px;
  }
}

.content-container {
  margin-top: 24px;
}

.content-container h1 {
  font-size: 34px;
  font-weight: bold;
}

.content-container h2 {
  font-size: 26px;
}

.content-container h3 {
  font-size: 22px;
  font-weight: lighter;
  margin-top: 14px;
}

.form-section {
  margin-top: 10px;
  margin-bottom: 30px;
}

.form-section h2, h3 {
  margin-top: 12px;
}

.form-section .has-badge {
  margin-left: 12px;
  margin-top: 4px;
}

.form-section.no-margins {
  margin-top: 0;
  margin-bottom: 0;
}

.form-field input {
  border-color: #A09696;
}

.form-input input[type=checkbox],
.form-input input[type=radio] {
  cursor: pointer;
}

.error-message {
  color: #D11111;
}

.inline-error-message {
  color: #D11111;
  margin-top: 8px;
  margin-bottom: 6px;
}

.info-message {
  margin-top: 8px;
  margin-bottom: 8px;
}

.table-section {
  margin-top: 30px;
}

button, .btn {
  min-width: 150px;
  min-height: 40px;
  font-size: 18px;
}

button > i,
.btn > i {
  margin-right: 8px;
}


a {
  text-decoration: none;
  color: #365bb9;
}

/* width */
::-webkit-scrollbar {
  width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b4b3b3;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #7c7c7c;
}

.download-export {
  display: none;
}

.dashboard-customize-controls {
  text-align: center;
  margin-top: 46px;
  margin-bottom: 52px;
}

.generators-grid {
  border-bottom: solid 1px #a4a4a4;
}

.generators-grid .location-group {
  background-color: #eeeeee;
  border-top: solid 1px #a4a4a4;
  min-height: 50px;
  font-weight: bold;
  padding: 12px 20px;
}

.generators-grid .location-generators {
  padding: 12px 20px;
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}

.generators-grid .location-generators button {
  background-color: white;
  border: none;
  border-radius: 4px;
  margin-right: 12px;
  min-height: 120px;
  text-align: left;
}

.generators-grid .location-generators button:hover {
  background-color: #EEEEEE;
}

.generators-grid .location-generators button:active {
  background-color: #A6A6A6;
}

.generators-grid .location-generators button:disabled {
  background-color: white;
}

.generators-grid .location-generators button > .genset-model {
  font-weight: bold;
  margin-top: 8px;
  margin-left: 8px;
}

.generators-grid .location-generators button > .generator-serial {
  font-weight: normal;
  margin-left: 8px;
  margin-top: 4px;
}

.generators-grid .location-generators button > .pin-indicator {
  display: block;
  margin-top: 16px;
  margin-right: 8px;
  margin-bottom: 8px;
  min-height: 28px;
  text-align: right;
}

.generators-grid.no-border {
  border-style: none;
}

.generators-grid .empty-indicator {
  color: #b6b3b3;
  margin: 52px 20px;
  text-align: center;
}

/* 1280x800 */
@media (max-width: 800px) {
  .login-container .login-form-container {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 100px 0 100px;
  }

  .login-container .change-password-form-container {
    grid-template-columns: repeat(1, 1fr);
    padding: 0 100px 0 100px;
  }

  .login-container .change-password-frame {
    grid-template-columns: repeat(1, 1fr);
  }

  .login-container .change-password-frame .change-password-info.hide-mobile {
    display: none;
  }

  .login-container .change-password-frame .change-password-info.show-mobile {
    display: block;
  }

  .login-container .change-password-frame .change-password-info.mobile-info {
    padding: 16px 16px 26px 16px;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .login-container .login-form-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .login-container .change-password-form-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .login-container .change-password-frame {
    grid-template-columns: repeat(2, 1fr);
    grid-column: 1/4;
    margin: 0 100px 0 100px;
  }

  .login-container .change-password-frame .change-password-info.hide-mobile {
    display: block;
  }

  .login-container .change-password-frame .change-password-info.show-mobile {
    display: none;
  }
}

@media (min-width: 1281px) {
  .login-container .login-form-container {
    grid-template-columns: repeat(3, 1fr);
  }

  .login-container .change-password-form-container {
    grid-template-columns: repeat(4, 1fr);
  }

  .login-container .change-password-frame {
    grid-template-columns: repeat(2, 1fr);
    grid-column: 2/4;
    padding: 26px 16px 16px 16px;
  }

  .login-container .change-password-frame .change-password-info.hide-mobile {
    display: block;
  }

  .login-container .change-password-frame .change-password-info.show-mobile {
    display: none;
  }
}

.login-container {
  background-repeat: no-repeat;
  background-attachment: local;
  background-size: cover;
  position: absolute;
  top: 80px;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.login-container .login-form-container {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  width: 100%;
  height: 85%;
  align-items: center;
}

.login-container .login-form {
  background-color: #A3B4DE;
  border: 1px solid #636A7B;
  box-shadow: 0 4px 4px gray;
  padding: 26px 16px 16px 16px;
  font-size: 18px;
  z-index: 0;
}

.login-container .change-password-form-container {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  width: 100%;
  height: 85%;
  align-items: center;
}

.login-container .change-password-frame {
  display: grid;
  background-color: #A3B4DE;
  border: 1px solid #636A7B;
  box-shadow: 0 4px 4px gray;
  font-size: 18px;
  z-index: 0;
}

.login-container .change-password-frame > div {
  padding: 26px 16px 16px 16px;
}

.login-container .change-password-frame .change-password-info {
  font-size: 16px;
}

.login-container .change-password-frame .change-password-form {
}

.login-container h1 {
  font-weight: bold;
  font-size: 36px;
  text-align: center;
  margin-bottom: 18px;
}

.login-container .info-message {
  margin-bottom: 22px;
}

.login-container .form-field {
  margin-bottom: 12px;
}

.login-container .validation-message {
}

.login-container .login-button {
  margin-top: 22px;
  margin-bottom: 20px;
  font-size: 24px;
}

.login-container .login-button span {
  margin-left: 20px;
}

.content-area {
  background-color: white;
  box-shadow: 0 0 8px #b6b3b3;
  padding: 24px;
  margin-top: 32px;
}

.background-area {
  padding: 24px;
  margin-top: 32px;
}

/* 1280x800 */
/* @media (max-width: 800px) {
  .widget-reminder-months {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .widget-reminder-months {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (min-width: 1281px) {
  .widget-reminder-months {
    grid-template-columns: repeat(3, 1fr);
  }
} */

.summary-widgets-container {
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 20px;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
}

.summary-widget {
  border: solid 2px #A7A7A7;
  border-radius: 4px;
  padding: 24px;
  display: grid;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  grid-template-columns: 1fr 1.5fr;
  min-height: 360px;
}

.summary-widget h2 {
  font-weight: bold;
  margin-bottom: 12px;
}

.summary-widget h5 {
  font-weight: bold;
  font-size: 18px;
}

.summary-widget .left {
  border-right: solid 1px #A7A7A7;
  padding-right: 16px;
}

.summary-widget .right {
  padding-bottom: 16px;
}

.summary-widget .controls {
  margin-top: 8px;
}

.job-history-summary-chart {
  display: inline;
  max-height: 400px;
  text-align: center;
}

.job-history-summary-empty {
  min-height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  color: #847D7D;
}

.widget-loading-indicator {
  grid-column: 1/4;
  color: #c3bebe;
  display: block;
  text-align: center;
  margin: 8px 0 8px 0;
  font-size: 52px;
}

.widget-loading-indicator > i {
  font-size: 200%;
}

.widget-empty-indicator {
  text-align: center;
  margin-bottom: 32px;
}

.widget-empty-indicator .widget-empty-icon {
  font-size: 9em;
  color: #e6e6e6;
}

.widget-empty-indicator .widget-empty-message {
  margin-top: 16px;
  color: #b6b3b3;
}
